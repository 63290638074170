<template>
  <iframe
    class="w-full h-96 my-auto"
    :src="youtubeUrl"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen></iframe>
</template>

<script>
export default {
  name: 'YoutubeIframe',
  props: {
    youtubeId: {
      type: String,
      required: true
    }
  },
  computed: {
    youtubeUrl () {
      return `//www.youtube.com/embed/${this.youtubeId}`
    }
  }
}
</script>

<style scoped>

</style>
